<template>
  <div>
    <el-table
      v-loading="config.loading"
      :data="tableData"
      border
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      :row-class-name="tableRowClassName"
      :span-method="arraySpanMethod"
    >
      <el-table-column align="center" prop="code" label="编号" width="150"> </el-table-column>
      <el-table-column align="center" prop="date" label="日期" width="100"> </el-table-column>
      <el-table-column align="center" prop="cangku_name" label="出入库房" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiao_name" label="材料名称" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige_name" label="材料规格" width="100"> </el-table-column>
      <el-table-column align="center" label="数量">
        <el-table-column align="center" prop="in_amount" label="合同内" width="70"> </el-table-column>
        <el-table-column align="center" prop="add_amount" label="增量" width="50"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="租金" v-if="canShowMoney">
        <el-table-column align="center" prop="zujin_danjia" label="单价" width="50"> </el-table-column>
        <el-table-column align="center" prop="zujin_hj" label="租金合计" width="70"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="押金" v-if="canShowMoney">
        <el-table-column align="center" prop="yajin_danjia" label="每片押金" width="70"> </el-table-column>
        <el-table-column align="center" prop="yajin_hj" label="合计" width="50"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="吊装费" v-if="canShowMoney">
        <el-table-column align="center" prop="diaozhuangfei_dunshu" label="吨数" width="70"> </el-table-column>
        <el-table-column align="center" prop="diaozhuangfei_hj" label="合计" width="70"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="运费" v-if="canShowMoney">
        <el-table-column align="center" prop="yunfei_danjia" label="单价" width="50"> </el-table-column>
        <el-table-column align="center" prop="yunfei_hj" label="合计" width="50"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" prop="chuban_hj" label="出板合计金额" width="70" v-if="canShowMoney"> </el-table-column>
      <el-table-column align="center" prop="jinpai_kehu" label="金牌客户" width="70" v-if="canShowMoney">
        <template slot-scope="scope">
          <span>{{ scope.row.jinpai_kehu ? '是' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="lengzhuang_lianmeng" label="冷弯联盟" width="70" v-if="canShowMoney">
        <template slot-scope="scope">
          <span>{{ scope.row.lengzhuang_lianmeng ? '是' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="shiji_huiru" label="实际汇入" width="70" v-if="canShowMoney"> </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="200"> </el-table-column>
      <el-table-column align="center" label="物资审核" width="100">
        <template slot-scope="scope" v-if="scope.row.type != 2 && scope.row.type != 3">
          <el-button
            size="mini"
            plain
            :type="scope.row.is_wuzishenhe === true ? 'success' : 'primary'"
            @click="handleWuziAudit(scope.row)"
            :disabled="btnWuziDisabled(scope.row)"
          >
            {{ scope.row.is_wuzishenhe ? '已审核' : '未审核' }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="财务审核" width="100">
        <template slot-scope="scope" v-if="scope.row.type != 2 && scope.row.type != 3">
          <el-button
            size="mini"
            plain
            :type="scope.row.is_caiwushenhe === true ? 'success' : 'primary'"
            @click="handleCaiwuAudit(scope.row)"
            :disabled="btnCaiwuDisable(scope.row)"
            >{{ scope.row.is_caiwushenhe ? '已审核' : '未审核' }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="库管确认" width="100">
        <template slot-scope="scope" v-if="scope.row.type != 2 && scope.row.type != 3">
          <el-button
            size="mini"
            plain
            :type="scope.row.is_queren === true ? 'success' : 'primary'"
            @click="handleKuguanConfirm(scope.row)"
            :disabled="btnKuguanConfirmDisable(scope.row)"
            >{{ scope.row.is_queren ? '已确认' : '未确认' }}</el-button
          >
        </template>
      </el-table-column>

      <el-table-column align="center" label="修改" width="100">
        <template slot-scope="scope" v-if="scope.row.type != 2 && scope.row.type != 3 && scope.row.modify_status == 'MODIFIED'">
          <el-button size="mini" type="text" @click="viewEditRecord(scope.row)">修改记录</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="管理" width="200">
        <template slot-scope="scope" v-if="scope.row.type != 2 && scope.row.type != 3">
          <el-button size="mini" type="danger" @click="zuofeiTongzhidan(scope.row)" v-if="false" :disabled="isDisabled_del(scope.row)">{{
            btnTxt_del(scope.row)
          }}</el-button>
          <el-button size="mini" type="danger" @click="handleEdit(scope.row)" :disabled="isDisabled_edit(scope.row)">{{ btnTxt_edit(scope.row) }}</el-button>
          <el-button size="mini" type="danger" @click="deleteTongzhidan(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
          <el-button
            size="mini"
            type="success"
            @click="ConvertToFormalTongzhidan(scope.row)"
            v-if="canConvertToFormal(scope.row)"
            :disabled="isDisabled_convert(scope.row)"
            >{{ btnTxt_convert(scope.row) }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import utils from '@/assets/js/utils'

export default {
  data() {
    return {
      pagesize: 20
    }
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canShowMoney: function() {
      // console.log('--------------------this.userType.code:', this.userType.code)
      if (this.userType.code === '6') return false
      else return true
    },
    canDelete() {
      return function(row) {
        if (this.userType.id === 1) return true
        else {
          if (this.userType.id === 2) {
            let added_person_time = new Date(row.added_person_time)
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          } else {
            return false
          }
        }
      }
    },
    btnWuziDisabled() {
      return function(row) {
        if (this.userType.code === '4' && row.is_wuzishenhe === false) return false
        else return true
      }
    },

    btnCaiwuDisable() {
      return function(row) {
        if (this.userType.code == '7' && row.is_wuzishenhe === true && row.is_caiwushenhe === false) return false
        else return true
      }
    },

    btnKuguanConfirmDisable() {
      return function(row) {
        if (this.userType.cailiaotongzhidan_confirm === true && row.is_wuzishenhe === true && row.is_caiwushenhe === true && row.is_queren === false)
          return false
        else return true
      }
    },

    /**
     * 借板通知单is_jieban和status按钮的状态变化：
     * 初始状态:is_jieban===true, status='NORMAL' ，按钮文本：'申请转为正式' ，分公司经理可操作（其他用户disabled）
     * 1. 分公司经理申请转为正式材料通知单：status='APPLY'，按钮文本：'等待物资审核'，物资可操作（其他用户disabled）
     * 2. 物资审核：status='WUZISHENHE'，按钮文本：‘等待财务审核'，财务可操作（其他用户disabled）
     * 3. 财务审核：status='CAIWUSHENHE',按钮文本：'确认转为正式'，分公司经理可操作（其他用户disabled）
     * 4. 分公司经理确认转为正式材料通知单：点击按钮后提示”该借板材料通知单将删除，请到正式材料通知单中查看相应的材料通知单“，is_jieban===false,status=‘FORMAL'
     */
    canConvertToFormal() {
      return function(row) {
        console.log('usertyp.code:', this.userType.code)
        console.log('row.is_jieban:', row.is_jieban)
        console.log('row.status:', row.status)

        if (row.is_jieban === true) {
          if (row.is_caiwushenhe === true && row.status != 'FORMAL') return true
          else return false
        }
      }
    },
    /**
     * 借板材料通知单转正式通知单按钮disabled状态设定
     */
    isDisabled_convert() {
      return function(row) {
        if (this.userType.code === '2' && row.is_jieban === true && row.status === 'NORMAL') return false
        else if (this.userType.code === '4' && row.status === 'APPLY') return false
        else if (this.userType.code === '7' && row.status === 'WUZISHENHE') return false
        else if (this.userType.code === '6' && row.status === 'CAIWUSHENHE') return false
        else return true
      }
    },
    /**
     * 借板材料通知单转正式通知单按钮文本设定
     */
    btnTxt_convert() {
      return function(row) {
        if (row.is_jieban === true && row.status === 'NORMAL') return '申请转为正式'
        else if (row.status === 'APPLY') return '等待物资转正审核'
        else if (row.status === 'WUZISHENHE') return '等待财务转正审核'
        else if (row.status === 'CAIWUSHENHE') return '确认转为正式'
        else return '申请转为正式'
      }
    },

    /**
     * 材料通知单修改按钮disabled状态设定
     */
    isDisabled_edit() {
      return function(row) {
        console.log('usertyp.code_____edit:', this.userType.code)
        console.log('row.modify_status_____edit:', row.modify_status)
        if (this.userType.code === '2' && (row.modify_status === 'NORMAL' || row.modify_status === 'MODIFIED')) return false
        else if (this.userType.code === '4' && row.modify_status === 'APPLY') return false
        else if (this.userType.code === '2' && row.modify_status === 'WUZISHENHE') return false
        else return true
      }
    },
    /**
     * 材料通知单修改按钮文本设定
     */
    btnTxt_edit() {
      return function(row) {
        if (row.modify_status === 'NORMAL' || row.modify_status === 'MODIFIED') return '申请修改'
        else if (row.modify_status === 'APPLY') return '等待物资批准修改'
        else if (row.modify_status === 'WUZISHENHE') return '修改'
        else return '申请修改'
      }
    },
    /**
     * 材料通知单作废按钮的状态变化：
     * 初始状态:del_status='NORMAL' ，按钮文本：'申请作废' ，分公司经理可操作（其他用户disabled）
     * 1. 分公司经理申请作废，del_status='APPLY'，按钮文本：'等待物资作废审核'，物资可操作（其他用户disabled）
     * 2. 物资审核，del_status='WUZISHENHE'，按钮文本：‘等待财务作废审核'，财务可操作（其他用户disabled）
     * 3. 财务审核：del_status='CAIWUSHENHE',按钮文本：'确认作废'，分公司经理可操作（其他用户disabled）
     * 4. 分公司经理确认作废：点击按钮后提示”该材料通知单将删除，确定要删除吗？“，del_status=‘DEL'
     */
    canDelFormal() {
      return function(row) {
        console.log('usertyp.code:', this.userType.code)
        console.log('row.del_status:', row.del_status)

        return true
      }
    },
    /**
     * 作废材料通知单按钮disabled状态设定
     */
    isDisabled_del() {
      return function(row) {
        if (this.userType.code === '2' && row.del_status === 'NORMAL') return false
        else if (this.userType.code === '4' && row.status === 'APPLY') return false
        else if (this.userType.code === '7' && row.status === 'WUZISHENHE') return false
        else if (this.userType.code === '2' && row.status === 'CAIWUSHENHE') return false
        else return true
      }
    },
    /**
     * 作废材料通知单按钮文本设定
     */
    btnTxt_del() {
      return function(row) {
        if (row.del_status === 'NORMAL') return '申请作废'
        else if (row.del_status === 'APPLY') return '等待物资作废审核'
        else if (row.del_status === 'WUZISHENHE') return '等待财务作废审核'
        else if (row.del_status === 'CAIWUSHENHE') return '确认作废'
        else return '申请作废'
      }
    }
  },

  methods: {
    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    //物资审核
    handleWuziAudit(row) {
      this.$emit('wuziAudit', row)
    },
    //财务审核
    handleCaiwuAudit(row) {
      this.$emit('caiwuAudit', row)
    },
    //库管确认
    handleKuguanConfirm(row) {
      this.$emit('kuguanConfirm', row)
    },

    //作废
    zuofeiTongzhidan(row) {
      this.$emit('zuofei', row)
    },
    //删除
    deleteTongzhidan(row) {
      this.$emit('del', row)
    },
    //修改
    handleEdit(row) {
      this.$emit('edit', row)
    },
    //显示修改记录
    viewEditRecord(row) {
      this.$emit('viewEditRecord', row)
    },
    //设置小计和合计行的状态
    tableRowClassName({ row, rowIndex }) {
      // console.log(row)
      // console.log(rowIndex)
      if (row.type === '2') {
        return 'xiaoji-row'
      } else if (row.type === '3') {
        return 'heji-row'
      } else if (rowIndex % 2 === 0) {
        return 'stripe-row'
      }
      return 'normal-row'
    },

    /**
     * 表格合并,小计和合计列合并
     * @param {*} param0
     * row 表格每一行的数据
     * column 表格每一列的数据
     * rowIndex 表格的行索引,不包括表头,从0开始
     * columnIndex 表格的列索引,从0开始
     */
    arraySpanMethod({ row, columnIndex }) {
      // console.log(row)
      if (row.type === '2' || row.type === '3') {
        if (columnIndex === 3) {
          // 从第2列开始(首列为第0列)
          // 这里返回的是行和列的合并数量,可以返回一个数组,也可以返回一个对象,效果一样
          // 这里rowspan为1是行有一行合并,colspan为3是列有3列合并,你要合并几行几列就写上相应的数字
          // return {
          //    rowspan: 1,
          //    colspan: 3
          //  }
          return [1, 2]
          // 这个判断是把合并的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
        } else if (columnIndex === 5) {
          return [1, 2]
        } else if (columnIndex === 4) {
          return [0, 0]
        }
      }
    },
    /**
     * 申请转为正式材料通知单
     */
    ConvertToFormalTongzhidan(row) {
      this.$emit('ConvertToFormalTongzhidan', row)
    }
  },
  created() {
    console.log('this.tabledata:', this.tableData)
  }
}
</script>
