<template>
  <div>
    <el-dialog
      width="600px"
      :title="cailiaotongzhidan_operateType === 'add' ? '新增材料通知单' : '修改材料通知单'"
      :visible.sync="cailiaotongzhidanDlg_isShow"
      :close-on-click-modal="false"
    >
      <CailiaotongzhidanForm :form="cailiaotongzhidanForm" ref="cailiaotongzhidanForm"></CailiaotongzhidanForm>
      <div slot="footer">
        <el-button @click="cailiaotongzhidanDlg_isShow = false"> 取消 </el-button>
        <el-button type="primary" @click="submitCailiaotongzhidanForm('cailiaotongzhidanForm')"> 确认添加 </el-button>
      </div>
    </el-dialog>

    <el-card shadow="never">
      <div slot="header">
        <span style="font-weight:bold">合同明细</span>
      </div>
      <div>
        <HeyuedetailTable :contractDetail="contractDetail"></HeyuedetailTable>
      </div>
    </el-card>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span style="font-weight:bold">材料通知单</span>
        <el-button
          type="primary"
          size="small"
          style="float: right;margin-right:100px"
          @click="addCailiaotongzhidan"
          v-if="usertype.code === '2' || usertype.code === '4'"
          >新 增</el-button
        >
      </div>

      <cailiaotongzhidan-table
        :tableData="cailiaotongzhidanTableData"
        :config="config"
        :userType="usertype"
        :isShowHeyueField="false"
        @changePage="getCailiaotongzhidanByID"
        @del="deleteCailiaotongzhidan"
        @wuziAudit="wuziAuditCailiaotongzhidan"
        @caiwuAudit="caiwuAuditCailiaotongzhidan"
        @managerConfirm="confirmCailiaotongzhidan"
      ></cailiaotongzhidan-table>
    </el-card>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span style="font-weight:bold">出板情况</span>
      </div>
      <chubanqingkuang-table :tableData="chubanqingkuangTableData" :config="config" :userType="usertype"></chubanqingkuang-table>
    </el-card>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
import HeyuedetailTable from './JiebanHeyueDetailTable'
import CailiaotongzhidanTable from '../cailiaoTongzhidan/CailiaotongzhidanTable'
import ChubanqingkuangTable from '../cailiaoTongzhidan/ChubanqingkuangTable'
import CailiaotongzhidanForm from '../cailiaoTongzhidan/CailiaotongzhidanForm'

export default {
  components: {
    HeyuedetailTable,
    CailiaotongzhidanTable,
    CailiaotongzhidanForm,
    ChubanqingkuangTable
  },
  props: {
    data: Object
  },

  data() {
    return {
      usertype: '',
      currentContract: '',
      xiangmuorgongdi: '',
      heyue_added: '',
      cailiaotongzhidanDlg_isShow: false,
      cailiaotongzhidan_operateType: 'add',
      contractDetail: {},
      cailiaotongzhidanForm: {},
      chukuForm: {},
      cailiaotongzhidans: [],
      cailiaotongzhidanTableData: [],
      chubanqingkuangTableData: [],

      config: {
        page: 1,
        total: 30,
        loading: false
      }
    }
  },
  methods: {
    //获取合同详情
    getContractDetail() {
      this.currentContract = this.$route.query.id
      console.log('contract_id:', this.currentContract)
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      console.log('user:', user)
      const api = '/getContractDetail/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          console.log('res.data', res.data)
          this.contractDetail = res.data
          if (this.usertype.contract_detail_amount === false) {
            this.contractDetail.contract_amount = '******'
          }
          if (this.usertype.contract_detail_real_amount === false) {
            this.contractDetail.real_amount = '******'
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //获取当前合约信息
    getHeyueInfo() {
      this.currentContract = this.$route.query.id
      // this.xiangmuorgongdi = this.$route.query.xiangmuorgongdi
      // this.heyue_added = this.$route.query.heyue_added
    },

    //获取材料通知单信息
    getCailiaotongzhidanByID() {
      let tongzhidans = []
      console.log('cailiao tongzhidan by id')
      const api = '/getCailiaotongzhidanByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.cailiaotongzhidanTableData = res.data.data.map(item => {
              let total_amount = parseInt(item.in_amount) + parseInt(item.add_amount)
              item.zujin_heji = item.zujin_danjia * total_amount
              item.yajin_heji = item.yajin_danjia * total_amount
              item.yunfei_heji = item.yunfei_danjia * total_amount
              item.chuban_heji = item.zujin_heji + item.yajin_heji + item.yunfei_heji + parseInt(item.diaozhuangfei_heji)

              this.cailiaotongzhidans.push(item.code)

              return item
            })
            this.chukuForm.cailiaotongzhidans = tongzhidans
            console.log('getCailiaotongzhidanByID', this.cailiaotongzhidanTableData)
            console.log('材料通知单数量', this.cailiaotongzhidanTableData.length)
            console.log('材料通知单编号', this.cailiaotongzhidans)

            //统计小计和总计
            let newArray = [] //存储原数据及小计和合计

            //按材料、材料规格排序
            newArray = newArray.concat(this.cailiaotongzhidanTableData)
            console.log('newArray----------------:', newArray)

            //1. 排序
            this.cailiaotongzhidanTableData.sort(function(a, b) {
              //如果“材料名称”相同，按照“材料规格”排序
              if (a.cailiao === b.cailiao) {
                return a.cailiaoguige < b.cailiaoguige ? -1 : 1
              } else {
                return a.cailiao < b.cailiao ? -1 : 1
              }
            })

            console.log('this.cailiaotongzhidanTableData', this.cailiaotongzhidanTableData)
            //2. 计算小计和总计
            let array = this.cailiaotongzhidanTableData
            // let newArray = [array[0]]

            let prior = array[0]
            let xiaoji = parseInt(prior.in_amount) + parseInt(prior.add_amount)
            let zongji = xiaoji

            console.log('xiaoji', xiaoji)
            for (let index = 1; index < array.length; index++) {
              console.log('the index:', index)
              let curr = array[index]
              //总计
              zongji = parseInt(zongji) + parseInt(curr.in_amount) + parseInt(curr.add_amount)
              if (curr.cailiao_name === prior.cailiao_name && curr.cailiaoguige_name === prior.cailiaoguige_name) {
                // newArray.push(curr)
                xiaoji += parseInt(curr.in_amount) + parseInt(curr.add_amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' } //type=2表示小计行，type=3表示合计行
                  newArray.push(temp)
                }
              } else {
                //生成前一个材料规格的小计
                let xiaojiTitle = prior.cailiao_name + '-' + prior.cailiaoguige_name + ' ' + '小计'
                let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' }
                newArray.push(temp)
                //插入本条记录
                // newArray.push(curr)
                //初始化新的小计
                xiaoji = parseInt(curr.in_amount) + parseInt(curr.add_amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' }
                  console.log('the last record', temp)
                  newArray.push(temp)
                }
              }
            }
            // console.log(zongji)

            let hejiTitle = '合计'
            let heji = { cailiao_name: hejiTitle, in_amount: zongji, type: '3' }
            newArray.push(heji)
            console.log('newarray', newArray)
            this.cailiaotongzhidanTableData = newArray
            console.log('材料通知单数量（处理后）', this.cailiaotongzhidanTableData.length)
          } else {
            console.log('材料通知单合约信息为空！')
          }
        })
    },
    //增加材料通知单
    addCailiaotongzhidan() {
      this.cailiaotongzhidanForm = {}
      this.cailiaotongzhidan_operateType = 'add'
      this.cailiaotongzhidanDlg_isShow = true
    },
    //材料通知单表单提交
    submitCailiaotongzhidanForm(formName) {
      console.log(formName)
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        console.log('add:' + this.Type)
        if (!this.cailiaotongzhidanForm.contract) this.cailiaotongzhidanForm.contract = this.currentContract
        this.cailiaotongzhidanForm.added_person = this.user_realname
        // this.chukuForm.added_person_time = utils.getDateTime()
        console.log('cailiaotongzhidanForm:', this.cailiaotongzhidanForm)
        this.$axios.post('/addCailiaotongzhidan/', this.cailiaotongzhidanForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加材料通知单成功!',
              duration: 2000
            })
            this.cailiaotongzhidanDlg_isShow = false
            this.getCailiaotongzhidanByID()
          } else {
            this.$message({
              type: 'warning',
              message: '添加失败',
              duration: 4000
            })
            console.log(res.data.msg)
          }
        })
      }
    },
    //删除材料通知单信息
    deleteCailiaotongzhidan(row) {
      this.$confirm('此操作将永久删除此材料通知单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteCailiaotongzhidan/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getCailiaotongzhidanByID()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    /*
    物资审核
    */
    wuziAuditCailiaotongzhidan(row) {
      this.$confirm('确认通过物资审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/wuziAuditCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '物资审核成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消物资审核'
          })
        })
    },

    /*
    财务审核
    */
    caiwuAuditCailiaotongzhidan(row) {
      this.$confirm('确认通过财务审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/caiwuAuditCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '财务审核成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消财务审核'
          })
        })
    },
    /*
    分公司经理确认
    */
    confirmCailiaotongzhidan(row) {
      this.$confirm('确认通过确认吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/confirmCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '确认成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认'
          })
        })
    },
    //获取出板情况
    getChubanqingkuangByID() {
      console.log('cailiao tongzhidan by id')
      const api = '/getChubanqingkuangByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          console.log('出版情况：', res)
          if (res.data.code === '1000') {
            this.chubanqingkuangTableData = res.data.data
          }
        })
    }
  },
  created() {
    this.getHeyueInfo()
    this.getCailiaotongzhidanByID()
    this.getChubanqingkuangByID()
    this.getContractDetail()
  }
}
</script>

<style scoped>
.center {
  background-color: #ffffff;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-size: 16px;
  margin-bottom: 5px;
}
.title {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-size: 16px;
  color: white;
}
.header-div {
  display: flex;
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  justify-content: space-between;
  background-color: rgb(116, 168, 190);
}
.button-div {
  padding-left: 20px;
}
</style>
